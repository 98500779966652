import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import {cognitoConfig} from './config';

var poolData = {
    UserPoolId:  cognitoConfig.userPoolId,
    ClientId: cognitoConfig.userPoolClientId,
    Storage: new CookieStorage({
      secure: process.env.NODE_ENV !== 'development', 
      domain: (process.env.NODE_ENV === 'development')? 'localhost':'.defactops.com',}),
};

var userPool = new CognitoUserPool(poolData);

function retrieveToken (tokenCallback, errorCallback) {
  try {
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
                console.log(`getSession error: ${err}`);
                errorCallback();
            } else if (!session.isValid()) {
                console.log("Invalid session");
                errorCallback();
            } else {                 
                cognitoUser.refreshSession(session.getRefreshToken(), function refreshCallback() {
                    tokenCallback(session.getAccessToken().getJwtToken());
                });                    
            }
        });
    } else {
        console.log('No cognito user');
        errorCallback();
    }
  } catch(e) {
    console.error(`RetrieveToken error: ${e}`);
  }
}

function GetToken () {
    return new Promise(
        (resolve, reject) => {
            retrieveToken(
                function (data) {
                  resolve(data);
                } , 
                function () {
                  console.log("GetToken reject")
                  reject()
                }
            )
        }
    )   
}

function SignOut() {
  userPool.getCurrentUser().signOut();
  window.location.href = "https://account.defactops.com";
}

export const cognito = {
    GetToken,
    SignOut,
};