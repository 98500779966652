import {cognito} from './cognito'
import axios from 'axios';

console.log(`The API url is ${process.env.VUE_APP_API}`);

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {

    const token = await cognito.GetToken();

    if (token) {
      config.headers['Authorization'] = token;
    }
    
    config.headers['Content-Type'] = 'application/json';
  
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function getTrialDates() {
  return axiosInstance.get(`${process.env.VUE_APP_API}/getTrialDates`);
};

function startTrial() {
  return axiosInstance.post(`${process.env.VUE_APP_API}/startFreeTrial`)
}

function getLicenseToken() {
  return axiosInstance.get(`${process.env.VUE_APP_API}/getLicenseToken`);
};

function getLicenseType() {
  return axiosInstance.get(`${process.env.VUE_APP_API}/getLicenseType`);
};

function getCheckoutSession() {
  return axiosInstance.post(`${process.env.VUE_APP_API}/create-checkout-session`);
}

function getCheckoutSessionStatus(session_id) {

  let data = JSON.stringify({
    session_id: session_id,
  });  

  return axiosInstance.post(`${process.env.VUE_APP_API}/checkout-session-status`, data);
}

export const dataService = {
  getTrialDates,
  startTrial,
  getLicenseToken,
  getLicenseType,
  getCheckoutSession,
  getCheckoutSessionStatus
};