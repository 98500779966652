
const state = () => ({
  preferences: {
    trialStartDate: 0,
    trialEndDate: 0,
    licenseType: 'Inactive',
    licenseToken: ''
  },
  checkoutSession: null,
  checkoutSessionStatus: {
    status: 'not yet defined',
    payment_status: 'not yet defined',
    customer_email: '',
  }
});

export default state;