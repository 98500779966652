export const GET_TRIAL_START_DATE = 'getTrialStartDate';
export const GET_TRIAL_END_DATE = 'getTrialEndDate';
export const GET_USER_TOKEN = 'getLicenseToken';
export const GET_LICENSE_TYPE = 'getLicenseType';
export const GET_CHECKOUT_SESSION = 'getCheckoutSession';
export const GET_CHECKOUT_SESSION_STATUS = 'getCheckoutSessionStatus';

const mutations = {
  [GET_TRIAL_START_DATE](state, value) {
    state.preferences.trialStartDate = value;
  },

  [GET_TRIAL_END_DATE](state, value) {
    state.preferences.trialEndDate = value;
  },  

  [GET_USER_TOKEN](state, value) {
    state.preferences.licenseToken = value;
  },

  [GET_LICENSE_TYPE](state, value) {
    state.preferences.licenseType = value;
  },

  [GET_CHECKOUT_SESSION](state, value) {
    state.checkoutSession = value;
  },

  [GET_CHECKOUT_SESSION_STATUS](state, value) {
    state.checkoutSessionStatus = value;
  },  
}

export default mutations;