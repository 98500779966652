import { createRouter, createWebHistory } from 'vue-router'

  const routes = [
  {
    path: '/',
    name: 'AppHome',
    //component: Home
    component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue')
  },
  {
    path: '/index.html',
    name: 'AppIndex',
    //component: Home
    component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue')
  },  
  {
    path: '/signup',
    name: 'SignUp',
    //component: SignUp
    component: () => import(/* webpackChunkName: "signup" */ '../components/signup/SignUp.vue')
  },
  {
    path: '/checkout/return',
    name: 'CheckoutReturn',
    //component: CheckoutReturn
    component: () => import(/* webpackChunkName: "checkoutReturn" */ '../components/signup/CheckoutReturn.vue')
  },     
  { 
    path: '/:pathMatch(.*)*', 
    name: 'AccountPageNotFound',
    component: () => import('../components/PageNotFound.vue') 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
