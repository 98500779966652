import { 
  GET_TRIAL_START_DATE, 
  GET_USER_TOKEN,
  GET_TRIAL_END_DATE, 
  GET_LICENSE_TYPE,
  GET_CHECKOUT_SESSION,
  GET_CHECKOUT_SESSION_STATUS, 
} from "./mutations";
import {dataService} from "../backend-access/data-service";

const actions = {
  async fetchTrialStartDate({commit}) {

    dataService.getTrialDates().then(response => {
      if (response.data.hasOwnProperty("startDate") && response.data.startDate.length > 0) {
        const trialStartDate = new Date(response.data.startDate);
        commit(GET_TRIAL_START_DATE, trialStartDate);
      }
    })
    .catch (error => {
      console.error(error);
    })

  },

  async fetchTrialEndDate({commit}) {
    dataService.getTrialDates().then(response => {
      if (response.data.hasOwnProperty("endDate") && response.data.endDate.length > 0) {
        const trialEndDate = new Date(response.data.endDate);
        commit(GET_TRIAL_END_DATE, trialEndDate);
      }
    })
    .catch (error => {
      console.error(error);
    })
  },

  async startTrial({dispatch}) {
    dataService.startTrial().then(response => {
      dispatch('fetchTrialStartDate');
      dispatch('fetchTrialEndDate');
      dispatch('fetchLicenseToken');
      dispatch('fetchLicenseType');
    })
  },

  async fetchLicenseToken({commit}) {

    dataService.getLicenseToken().then(response => {
      if (response.data.hasOwnProperty('licenseToken') && response.data.licenseToken.length > 0) {
        commit(GET_USER_TOKEN, response.data.licenseToken);
      }
    })
    .catch (error => {
      console.error(error);
    })

  },

  async fetchLicenseType({commit}) {

    dataService.getLicenseType().then(response => {
      if (response.data.hasOwnProperty('licenseType') && response.data.licenseType.length > 0) {
        commit(GET_LICENSE_TYPE, response.data.licenseType);
      }
    })
    .catch (error => {
      console.error(error);
    })

  },  

  async fetchCheckoutSession({commit}) {
    dataService.getCheckoutSession().then(response => {
      if (response.status == 200) {
        commit(GET_CHECKOUT_SESSION, response.data.session);
      }
    })
  },

  async fetchCheckoutSessionStatus({commit}, session_id) {
    dataService.getCheckoutSessionStatus(session_id).then(response => {
      if (response.status == 200) {
        commit(GET_CHECKOUT_SESSION_STATUS, response.data.session);
      }
    })
  }  
}

export default actions;